<template>
  <ModalContainer>
    <template #body>
      <NinetyNineLogo link />
      <div class="max-w-md mx-auto">
        <img width="100%" src="@/assets/img/modal-go-to-app@2x.png" alt="" />
        <div class="text-center">
          <h1 class="text-xl font-medium my-4">Mejor desde la app</h1>
          <p class="text-gray-500">
            Si quieres usar Ninety Nine desde un móvil, tu experiencia será
            mucho mejor desde la app.
          </p>
          <BaseButton @click="goToAppLink" class="mt-4" primary
            >Ir a la app de Ninety Nine</BaseButton
          >
        </div>
      </div>
    </template>
  </ModalContainer>
</template>
<script>
import NinetyNineLogo from "@/components/base/NinetyNineLogo.vue";

export default {
  components: {
    NinetyNineLogo,
  },
  methods: {
    goToAppLink() {
      window.open("https://ninetynine.onelink.me/41QK/WTprofile");
    },
  },
};
</script>
